import { IPolicyType } from '../../../redux/slices/PolicySlice/types';

export interface IDropdownData {
    name: string;
    value: string;
    availablility?: IPolicyType[];
}

export const placementMeta: Record<string, IDropdownData[]> = {
    insurerName: [
        {
            name: 'Not Applicable',
            value: 'Not Applicable'
        },
        {
            name: 'Aditya Birla Sun Life Insurance Company Ltd',
            value: 'Aditya Birla Sun Life Insurance Company Ltd'
        },
        {
            name: 'Bajaj Allianz',
            value: 'Bajaj Allianz'
        },
        {
            name: 'Bharati AXA',
            value: 'Bharati AXA'
        },
        {
            name: 'Care Health Insurance',
            value: 'Care Health Insurance'
        },
        {
            name: 'Cholamandalam MS General Insurance',
            value: 'Cholamandalam MS General Insurance'
        },
        {
            name: 'Family Health Plan (TPA) Ltd',
            value: 'Family Health Plan (TPA) Ltd'
        },
        {
            name: 'Future Generali India Insurance Co Ltd',
            value: 'Future Generali India Insurance Co Ltd'
        },
        {
            name: 'Go Digit',
            value: 'Go Digit'
        },
        {
            name: 'Health India TPA',
            value: 'Health India TPA'
        },
        {
            name: 'ICICI Lombard',
            value: 'ICICI Lombard'
        },
        {
            name: 'ICICI Prudential Life Insurance',
            value: 'ICICI Prudential Life Insurance'
        },
        {
            name: 'Liberty General Insurance',
            value: 'Liberty General Insurance'
        },
        {
            name: 'Manipal Cigna Health Insurance Co Ltd',
            value: 'Manipal Cigna Health Insurance Co Ltd'
        },
        {
            name: 'Niva Bupa',
            value: 'Niva Bupa'
        },
        {
            name: 'MD India',
            value: 'MD India'
        },
        {
            name: 'Medi Assist Insurance India TPA',
            value: 'Medi Assist Insurance India TPA'
        },
        {
            name: 'Paramount Health Services & Insurance TPA',
            value: 'Paramount Health Services & Insurance TPA'
        },
        {
            name: 'Pramerica Life Insurance',
            value: 'Pramerica Life Insurance'
        },
        {
            name: 'Raksha TPA',
            value: 'Raksha TPA'
        },
        {
            name: 'Reliance General Insurance',
            value: 'Reliance General Insurance'
        },
        {
            name: 'SBI General Insurance',
            value: 'SBI General Insurance'
        },
        {
            name: 'Shriram General Insurance Co Ltd',
            value: 'Shriram General Insurance Co Ltd'
        },
        {
            name: 'Star Health',
            value: 'Star Health'
        },
        {
            name: 'The New India Assurance Co Ltd',
            value: 'The New India Assurance Co Ltd'
        },
        {
            name: 'Royal Sundaram General Insurance Co Ltd',
            value: 'Royal Sundaram General Insurance Co Ltd'
        },
        {
            name: 'Magma HDI General Insurance Co Ltd',
            value: 'Magma HDI General Insurance Co Ltd'
        },
        {
            name: 'Max Life Insurance Co Ltd',
            value: 'Max Life Insurance Co Ltd'
        },
        {
            name: 'The Oriental Insurance Co Ltd',
            value: 'The Oriental Insurance Co Ltd'
        },
        {
            name: 'United India Insurance Co Ltd',
            value: 'United India Insurance Co Ltd'
        },
        {
            name: 'LIC of India',
            value: 'LIC of India'
        },
        {
            name: 'Iffco Tokio General Insurance Co Ltd',
            value: 'Iffco Tokio General Insurance Co Ltd'
        },
        {
            name: 'Aditya Birla Health Insurance Co ltd',
            value: 'Aditya Birla Health Insurance Co ltd'
        },
        { name: 'Medvantage Insurance TPA Private Limited', value: 'Medvantage Insurance TPA Private Limited' },
        {
            name: 'Heritage Health Insurance TPA Private Limited',
            value: 'Heritage Health Insurance TPA Private Limited'
        },
        { name: 'Vidal Health Insurance TPA Private Limited', value: 'Vidal Health Insurance TPA Private Limited' },
        {
            name: 'East West Assist Insurance TPA Private Limited',
            value: 'East West Assist Insurance TPA Private Limited'
        },
        { name: 'Medsave Health Insurance TPA Limited', value: 'Medsave Health Insurance TPA Limited' },
        { name: 'Good Health Insurance TPA Limited', value: 'Good Health Insurance TPA Limited' },
        { name: 'Park Mediclaim Insurance TPA Private Limited', value: 'Park Mediclaim Insurance TPA Private Limited' },
        { name: 'Safeway Insurance TPA Private Limited', value: 'Safeway Insurance TPA Private Limited' },
        { name: 'Anmol Medicare Insurance TPA Limited', value: 'Anmol Medicare Insurance TPA Limited' },
        { name: 'Rothshield Insurance TPA Limited', value: 'Rothshield Insurance TPA Limited' },
        { name: 'Ericson Insurance TPA Private Limited', value: 'Ericson Insurance TPA Private Limited' },
        { name: 'Health Insurance TPA of India Limited', value: 'Health Insurance TPA of India Limited' },
        { name: 'Agriculture Insurance Co. of India Ltd.', value: 'Agriculture Insurance Co. of India Ltd.' },
        { name: 'Bajaj Allianz General Insurance Co. Ltd.', value: 'Bajaj Allianz General Insurance Co. Ltd.' },
        { name: 'Bharti Axa General Insurance Co. Ltd.', value: 'Bharti Axa General Insurance Co. Ltd.' },
        {
            name: 'Export Credit Guarantee Corporation of India Ltd.',
            value: 'Export Credit Guarantee Corporation of India Ltd.'
        },
        { name: 'HDFC ERGO General Insurance Co. Ltd.', value: 'HDFC ERGO General Insurance Co. Ltd.' },
        { name: 'L&T General Insurance Co. Ltd.', value: 'L&T General Insurance Co. Ltd.' },
        { name: 'National Insurance Co. Ltd.', value: 'National Insurance Co. Ltd.' },
        { name: 'Raheja QBE General Insurance Co. Ltd.', value: 'Raheja QBE General Insurance Co. Ltd.' },
        { name: 'Tata AIG General Insurance Co. Ltd.', value: 'Tata AIG General Insurance Co. Ltd.' },
        { name: 'Universal Sompo General Insurance Co. Ltd.', value: 'Universal Sompo General Insurance Co. Ltd.' },
        { name: 'Kotak Mahindra General Insurance Co. Ltd.', value: 'Kotak Mahindra General Insurance Co. Ltd.' },
        { name: 'Reliance Health Insurance Limited', value: 'Reliance Health Insurance Limited' },
        { name: 'Acko General Insurance Limited', value: 'Acko General Insurance Limited' },
        { name: 'DHFL General Insurance Co.Ltd.', value: 'DHFL General Insurance Co.Ltd.' },
        { name: 'Edelweiss General Insurance Co.Ltd.', value: 'Edelweiss General Insurance Co.Ltd.' },
        { name: 'Aegon Life Insurance Co. Ltd.', value: 'Aegon Life Insurance Co. Ltd.' },
        { name: 'Aviva Life Insurance Co. India Ltd.', value: 'Aviva Life Insurance Co. India Ltd.' },
        {
            name: 'Canara HSBC Oriental Bank of Commerce Life Insurance Co. Ltd.',
            value: 'Canara HSBC Oriental Bank of Commerce Life Insurance Co. Ltd.'
        },
        { name: 'Edelweiss Tokio Life Insurance Co. Ltd', value: 'Edelweiss Tokio Life Insurance Co. Ltd' },
        { name: 'Exide Life Insurance Co. Ltd.', value: 'Exide Life Insurance Co. Ltd.' },
        {
            name: 'Future Generali India Life Insurance Co. Ltd.',
            value: 'Future Generali India Life Insurance Co. Ltd.'
        },
        { name: 'HDFC Standard Life Insurance Co. Ltd.', value: 'HDFC Standard Life Insurance Co. Ltd.' },
        { name: 'IDBI Federal Life Insurance Co. Ltd.', value: 'IDBI Federal Life Insurance Co. Ltd.' },
        { name: 'IndiaFirst Life Insurance Co. Ltd', value: 'IndiaFirst Life Insurance Co. Ltd' },
        {
            name: 'Kotak Mahindra Old Mutual Life Insurance Ltd.',
            value: 'Kotak Mahindra Old Mutual Life Insurance Ltd.'
        },
        { name: 'PNB MetLife India Insurance Co. Ltd.', value: 'PNB MetLife India Insurance Co. Ltd.' },
        { name: 'Reliance Life Insurance Co. Ltd.', value: 'Reliance Life Insurance Co. Ltd.' },
        { name: 'SBI Life Insurance Co. Ltd.', value: 'SBI Life Insurance Co. Ltd.' },
        { name: 'Sahara India Life Insurance Co. Ltd.', value: 'Sahara India Life Insurance Co. Ltd.' },
        { name: 'Shriram Life Insurance Co. Ltd.', value: 'Shriram Life Insurance Co. Ltd.' },
        { name: 'Star Union Dai-Ichi Life Insurance Co. Ltd.', value: 'Star Union Dai-Ichi Life Insurance Co. Ltd.' },
        { name: 'Tata AIA Life Insurance Co. Ltd.', value: 'Tata AIA Life Insurance Co. Ltd.' }
    ],
    tpaName: [
        {
            name: 'Not Applicable',
            value: 'Not Applicable'
        },
        {
            name: 'Aditya Birla Sun Life Insurance Company Ltd',
            value: 'Aditya Birla Sun Life Insurance Company Ltd'
        },
        {
            name: 'Bajaj Allianz',
            value: 'Bajaj Allianz'
        },
        {
            name: 'Bharati AXA',
            value: 'Bharati AXA'
        },
        {
            name: 'Care Health Insurance',
            value: 'Care Health Insurance'
        },
        {
            name: 'Cholamandalam MS General Insurance',
            value: 'Cholamandalam MS General Insurance'
        },
        {
            name: 'Family Health Plan (TPA) Ltd',
            value: 'Family Health Plan (TPA) Ltd'
        },
        {
            name: 'Future Generali India Insurance Co Ltd',
            value: 'Future Generali India Insurance Co Ltd'
        },
        {
            name: 'Go Digit',
            value: 'Go Digit'
        },
        {
            name: 'Health India TPA',
            value: 'Health India TPA'
        },
        {
            name: 'ICICI Lombard',
            value: 'ICICI Lombard'
        },
        {
            name: 'ICICI Prudential Life Insurance',
            value: 'ICICI Prudential Life Insurance'
        },
        {
            name: 'Liberty General Insurance',
            value: 'Liberty General Insurance'
        },
        {
            name: 'Manipal Cigna Health Insurance Co Ltd',
            value: 'Manipal Cigna Health Insurance Co Ltd'
        },
        {
            name: 'Niva Bupa',
            value: 'Niva Bupa'
        },
        {
            name: 'MD India',
            value: 'MD India'
        },
        {
            name: 'Medi Assist Insurance India TPA',
            value: 'Medi Assist Insurance India TPA'
        },
        {
            name: 'Paramount Health Services & Insurance TPA',
            value: 'Paramount Health Services & Insurance TPA'
        },
        {
            name: 'Pramerica Life Insurance',
            value: 'Pramerica Life Insurance'
        },
        {
            name: 'Raksha TPA',
            value: 'Raksha TPA'
        },
        {
            name: 'Reliance General Insurance',
            value: 'Reliance General Insurance'
        },
        {
            name: 'SBI General Insurance',
            value: 'SBI General Insurance'
        },
        {
            name: 'Shriram General Insurance Co Ltd',
            value: 'Shriram General Insurance Co Ltd'
        },
        {
            name: 'Star Health',
            value: 'Star Health'
        },
        {
            name: 'The New India Assurance Co Ltd',
            value: 'The New India Assurance Co Ltd'
        },
        {
            name: 'Royal Sundaram General Insurance Co Ltd',
            value: 'Royal Sundaram General Insurance Co Ltd'
        },
        {
            name: 'Magma HDI General Insurance Co Ltd',
            value: 'Magma HDI General Insurance Co Ltd'
        },
        {
            name: 'Max Life Insurance Co Ltd',
            value: 'Max Life Insurance Co Ltd'
        },
        {
            name: 'The Oriental Insurance Co Ltd',
            value: 'The Oriental Insurance Co Ltd'
        },
        {
            name: 'United India Insurance Co Ltd',
            value: 'United India Insurance Co Ltd'
        },
        {
            name: 'LIC of India',
            value: 'LIC of India'
        },
        {
            name: 'Iffco Tokio General Insurance Co Ltd',
            value: 'Iffco Tokio General Insurance Co Ltd'
        },
        {
            name: 'Aditya Birla Health Insurance Co ltd',
            value: 'Aditya Birla Health Insurance Co ltd'
        },
        { name: 'Medvantage Insurance TPA Private Limited', value: 'Medvantage Insurance TPA Private Limited' },
        {
            name: 'Heritage Health Insurance TPA Private Limited',
            value: 'Heritage Health Insurance TPA Private Limited'
        },
        { name: 'Vidal Health Insurance TPA Private Limited', value: 'Vidal Health Insurance TPA Private Limited' },
        {
            name: 'East West Assist Insurance TPA Private Limited',
            value: 'East West Assist Insurance TPA Private Limited'
        },
        { name: 'Medsave Health Insurance TPA Limited', value: 'Medsave Health Insurance TPA Limited' },
        { name: 'Good Health Insurance TPA Limited', value: 'Good Health Insurance TPA Limited' },
        { name: 'Park Mediclaim Insurance TPA Private Limited', value: 'Park Mediclaim Insurance TPA Private Limited' },
        { name: 'Safeway Insurance TPA Private Limited', value: 'Safeway Insurance TPA Private Limited' },
        { name: 'Anmol Medicare Insurance TPA Limited', value: 'Anmol Medicare Insurance TPA Limited' },
        { name: 'Rothshield Insurance TPA Limited', value: 'Rothshield Insurance TPA Limited' },
        { name: 'Ericson Insurance TPA Private Limited', value: 'Ericson Insurance TPA Private Limited' },
        { name: 'Health Insurance TPA of India Limited', value: 'Health Insurance TPA of India Limited' },
        { name: 'Agriculture Insurance Co. of India Ltd.', value: 'Agriculture Insurance Co. of India Ltd.' },
        { name: 'Bajaj Allianz General Insurance Co. Ltd.', value: 'Bajaj Allianz General Insurance Co. Ltd.' },
        { name: 'Bharti Axa General Insurance Co. Ltd.', value: 'Bharti Axa General Insurance Co. Ltd.' },
        {
            name: 'Export Credit Guarantee Corporation of India Ltd.',
            value: 'Export Credit Guarantee Corporation of India Ltd.'
        },
        { name: 'HDFC ERGO General Insurance Co. Ltd.', value: 'HDFC ERGO General Insurance Co. Ltd.' },
        { name: 'L&T General Insurance Co. Ltd.', value: 'L&T General Insurance Co. Ltd.' },
        { name: 'National Insurance Co. Ltd.', value: 'National Insurance Co. Ltd.' },
        { name: 'Raheja QBE General Insurance Co. Ltd.', value: 'Raheja QBE General Insurance Co. Ltd.' },
        { name: 'Tata AIG General Insurance Co. Ltd.', value: 'Tata AIG General Insurance Co. Ltd.' },
        { name: 'Universal Sompo General Insurance Co. Ltd.', value: 'Universal Sompo General Insurance Co. Ltd.' },
        { name: 'Kotak Mahindra General Insurance Co. Ltd.', value: 'Kotak Mahindra General Insurance Co. Ltd.' },
        { name: 'Reliance Health Insurance Limited', value: 'Reliance Health Insurance Limited' },
        { name: 'Acko General Insurance Limited', value: 'Acko General Insurance Limited' },
        { name: 'DHFL General Insurance Co.Ltd.', value: 'DHFL General Insurance Co.Ltd.' },
        { name: 'Edelweiss General Insurance Co.Ltd.', value: 'Edelweiss General Insurance Co.Ltd.' },
        { name: 'Aegon Life Insurance Co. Ltd.', value: 'Aegon Life Insurance Co. Ltd.' },
        { name: 'Aviva Life Insurance Co. India Ltd.', value: 'Aviva Life Insurance Co. India Ltd.' },
        {
            name: 'Canara HSBC Oriental Bank of Commerce Life Insurance Co. Ltd.',
            value: 'Canara HSBC Oriental Bank of Commerce Life Insurance Co. Ltd.'
        },
        { name: 'Edelweiss Tokio Life Insurance Co. Ltd', value: 'Edelweiss Tokio Life Insurance Co. Ltd' },
        { name: 'Exide Life Insurance Co. Ltd.', value: 'Exide Life Insurance Co. Ltd.' },
        {
            name: 'Future Generali India Life Insurance Co. Ltd.',
            value: 'Future Generali India Life Insurance Co. Ltd.'
        },
        { name: 'HDFC Standard Life Insurance Co. Ltd.', value: 'HDFC Standard Life Insurance Co. Ltd.' },
        { name: 'IDBI Federal Life Insurance Co. Ltd.', value: 'IDBI Federal Life Insurance Co. Ltd.' },
        { name: 'IndiaFirst Life Insurance Co. Ltd', value: 'IndiaFirst Life Insurance Co. Ltd' },
        {
            name: 'Kotak Mahindra Old Mutual Life Insurance Ltd.',
            value: 'Kotak Mahindra Old Mutual Life Insurance Ltd.'
        },
        { name: 'PNB MetLife India Insurance Co. Ltd.', value: 'PNB MetLife India Insurance Co. Ltd.' },
        { name: 'Reliance Life Insurance Co. Ltd.', value: 'Reliance Life Insurance Co. Ltd.' },
        { name: 'SBI Life Insurance Co. Ltd.', value: 'SBI Life Insurance Co. Ltd.' },
        { name: 'Sahara India Life Insurance Co. Ltd.', value: 'Sahara India Life Insurance Co. Ltd.' },
        { name: 'Shriram Life Insurance Co. Ltd.', value: 'Shriram Life Insurance Co. Ltd.' },
        { name: 'Star Union Dai-Ichi Life Insurance Co. Ltd.', value: 'Star Union Dai-Ichi Life Insurance Co. Ltd.' },
        { name: 'Tata AIA Life Insurance Co. Ltd.', value: 'Tata AIA Life Insurance Co. Ltd.' }
    ],
    installmentPremium: [
        {
            name: 'Annual',
            value: 'Annual'
        },
        {
            name: 'Half-yearly',
            value: 'Half-yearly'
        },
        {
            name: 'Quarterly',
            value: 'Quarterly'
        }
    ],
    sumInsuredApproach: [
        {
            name: 'Individual',
            value: 'Individual'
        },
        {
            name: 'Fixed Floater',
            value: 'Fixed Floater'
        },
        {
            name: 'Flexi Floater',
            value: 'Flexi Floater'
        }
    ],
    waitingPeriod: [
        {
            name: 'Waived off',
            value: 'Waived off'
        },
        {
            name: 'Applicable',
            value: 'Applicable'
        }
    ],
    yearWaitingPeriod: [
        {
            name: 'Waived off',
            value: 'Waived off'
        },
        {
            name: 'Applicable',
            value: 'Applicable'
        }
    ],
    documentsRequired: [
        {
            name: 'Only Soft Copy',
            value: 'Only Soft Copy'
        },
        {
            name: 'Only Hard Copy',
            value: 'Only Hard Copy'
        },
        {
            name: 'Both Soft and Hard Copy',
            value: 'Both Soft and Hard Copy'
        }
    ],

    documentsType: [
        {
            value: 'N/A',
            name: 'Not Applicable'
        },
        {
            value: 'COURIER',
            name: 'Loop Pune Office'
        },
        {
            value: 'HELP_DESK',
            name: 'Company Helpdesk'
        }
    ],
    preExistingDiseaseBenefit: [
        {
            name: 'Covered from day 1',
            value: 'Covered from day 1'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        }
    ],
    nineMonthsWaitingPeriod: [
        {
            name: 'Waived off',
            value: 'Waived off'
        },
        {
            name: 'Applicable',
            value: 'Applicable'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        }
    ],
    newBornBaby: [
        {
            name: 'Covered from day 1',
            value: 'Covered from day 1'
        },
        {
            name: 'Covered from 90 days',
            value: 'Covered from 90 days'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        }
    ],
    preHospitalizationExpenses: [
        {
            name: '15 days',
            value: '15 days',
            availablility: ['COVID']
        },
        {
            name: '30 days',
            value: '30 days',
            availablility: ['GMC', 'COVID']
        },
        {
            name: '60 days',
            value: '60 days',
            availablility: ['GMC']
        }
    ],
    postHospitalizationExpenses: [
        {
            name: '30 days',
            value: '30 days',
            availablility: ['COVID']
        },
        {
            name: '60 days',
            value: '60 days',
            availablility: ['COVID', 'GMC']
        },
        {
            name: '90 days',
            value: '90 days',
            availablility: ['GMC']
        },
        {
            name: '120 days',
            value: '120 days',
            availablility: ['GMC']
        }
    ],
    internalCongenitalDisease: [
        {
            name: 'Not covered',
            value: 'Not covered'
        },
        {
            name: 'Covered',
            value: 'Covered'
        }
    ],
    externalCongenitalDisease: [
        {
            name: 'Not covered',
            value: 'Not covered'
        },
        {
            name: 'Covered for life threatening conditions',
            value: 'Covered for life threatening conditions'
        }
    ],
    hospitalizationArisingOutOfTerrorism: [
        {
            name: 'Covered',
            value: 'Covered'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        }
    ],
    lasikSurgery: [
        {
            name: 'Not covered',
            value: 'Not covered'
        },
        {
            name: 'Covered if power is +/-5',
            value: 'Covered if power is +/-5'
        },
        {
            name: 'Covered if power is +/-6.5',
            value: 'Covered if power is +/-6.5'
        },
        {
            name: 'Covered if power is +/-7.5',
            value: 'Covered if power is +/-7.5'
        },
        {
            name: 'Covered if power is +/-8.5',
            value: 'Covered if power is +/-8.5'
        }
    ],
    dentalTreatment: [
        {
            name: 'Covered (due to accident only)',
            value: 'Covered (due to accident only)'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        },
        {
            name: 'To refer to the Special condition',
            value: 'To refer to the Special condition'
        }
    ],
    claimsIntimation: [
        {
            name: 'Waived off',
            value: 'Waived off'
        },
        {
            name: '2 hours',
            value: '2 hours'
        },
        {
            name: '4 hours',
            value: '4 hours'
        },
        {
            name: '12 hours',
            value: '12 hours'
        },
        {
            name: '24 hours',
            value: '24 hours'
        },
        {
            name: '48 hours',
            value: '48 hours'
        },
        {
            name: '72 hours',
            value: '72 hours'
        },
        {
            name: '7 days',
            value: '7 days'
        },
        {
            name: '15 days',
            value: '15 days'
        },
        {
            name: '30 days',
            value: '30 days'
        }
    ],
    claimsSubmission: [
        {
            name: '7 days',
            value: '7 days'
        },
        {
            name: '10 days',
            value: '10 days'
        },
        {
            name: '15 days',
            value: '15 days'
        },
        {
            name: '30 days',
            value: '30 days'
        },
        {
            name: '45 days',
            value: '45 days'
        }
    ],
    sumInsuredStructure: [
        {
            name: 'Graded Tier',
            value: 'Graded Tier',
            availablility: ['GMC', 'GPA', 'GTL', 'COVID']
        },
        {
            name: 'Flat Sum Insured',
            value: 'Flat Sum Insured',
            availablility: ['GMC', 'GPA', 'GTL', 'COVID']
        },
        {
            name: 'CTC Multiplier',
            value: 'CTC Multiplier',
            availablility: ['GPA', 'GTL']
        }
    ],
    terrorismExclusionClause: [
        {
            name: 'Covered',
            value: 'Covered'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        }
    ],
    suicideCoverage: [
        {
            name: 'Covered',
            value: 'Covered'
        },
        {
            name: 'Not covered',
            value: 'Not covered'
        }
    ],
    activeAtWorkClause: [
        {
            name: 'Waived off',
            value: 'Waived off'
        },
        {
            name: 'Applicable',
            value: 'Applicable'
        }
    ],
    familyDefinition: [
        {
            name: 'Self',
            value: 'Self',
            availablility: ['GMC', 'GPA', 'GTL', 'COVID']
        },
        {
            name: 'Self + Spouse',
            value: 'Self + Spouse',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 1 Kid',
            value: 'Self + Spouse + 1 Kid',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 2 Kids',
            value: 'Self + Spouse + 2 Kids',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 3 Kids',
            value: 'Self + Spouse + 3 Kids',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 4 Kids',
            value: 'Self + Spouse + 4 Kids',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 2 Kids + Parents',
            value: 'Self + Spouse + 2 Kids + Parents',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 3 Kids + Parents',
            value: 'Self + Spouse + 3 Kids + Parents',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 4 Kids + Parents',
            value: 'Self + Spouse + 4 Kids + Parents',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 2 Kids + Parents (including in-laws)',
            value: 'Self + Spouse + 2 Kids + Parents (including in-laws)',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 3 Kids + Parents (including in-laws)',
            value: 'Self + Spouse + 3 Kids + Parents (including in-laws)',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 4 Kids + Parents (including in-laws)',
            value: 'Self + Spouse + 4 Kids + Parents (including in-laws)',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 2 Kids + Parents OR parent-in-laws',
            value: 'Self + Spouse + 2 Kids + Parents OR parent-in-laws',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 3 Kids + Parents OR parent-in-laws',
            value: 'Self + Spouse + 3 Kids + Parents OR parent-in-laws',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 4 Kids + Parents OR parent-in-laws',
            value: 'Self + Spouse + 4 Kids + Parents OR parent-in-laws',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 2 Kids + Parents + Parents-in-law',
            value: 'Self + Spouse + 2 Kids + Parents + Parents-in-law',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 3 Kids + Parents + Parents-in-law',
            value: 'Self + Spouse + 3 Kids + Parents + Parents-in-law',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Self + Spouse + 4 Kids + Parents + Parents-in-law',
            value: 'Self + Spouse + 4 Kids + Parents + Parents-in-law',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Parents Only',
            value: 'Parents Only',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Parents (including in-laws)',
            value: 'Parents (including in-laws)',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Parents OR parents-in-law',
            value: 'Parents OR parents-in-law',
            availablility: ['GMC', 'COVID']
        },
        {
            name: 'Parents + Parents-in-law',
            value: 'Parents + Parents-in-law',
            availablility: ['GMC', 'COVID']
        }
    ],
    policyTerm: [
        {
            name: '3.5 months',
            value: '3.5 months'
        },
        {
            name: '6.5 months',
            value: '6.5 months'
        },
        {
            name: '9.5 months',
            value: '9.5 months'
        },
        {
            name: '12 months',
            value: '12 months'
        }
    ],
    ageLimit: [
        {
            name: 'upto 60 years',
            value: 'upto 60 years'
        },
        {
            name: 'upto 65 years',
            value: 'upto 65 years'
        }
    ],
    initialFifteenDaysWaitingPeriod: [
        {
            name: 'Applicable',
            value: 'Applicable'
        },
        {
            name: 'Not applicable',
            value: 'Not applicable'
        }
    ],
    pedWaitingPeriod: [
        {
            name: 'Applicable',
            value: 'Applicable'
        },
        {
            name: 'Not applicable',
            value: 'Not applicable'
        }
    ],
    coMorbidityExclusionClause: [
        {
            name: 'Waived off',
            value: 'Waived off'
        },
        {
            name: 'Not Waived off',
            value: 'Not Waived off'
        }
    ],
    coPayment: [
        {
            name: 'Not applicable',
            value: 'Not applicable'
        },
        {
            name: '5%',
            value: '0.05'
        },
        {
            name: '10%',
            value: '0.1'
        },
        {
            name: '15%',
            value: '0.15'
        },
        {
            name: '20%',
            value: '0.2'
        },
        {
            name: '25%',
            value: '0.25'
        },
        {
            name: '30%',
            value: '0.3'
        },
        {
            name: '35%',
            value: '0.35'
        }
    ]
};
